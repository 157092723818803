import {
	IconHome,
	IconBuildingSkyscraper,
	IconHierarchy3,
	IconDatabase,
	IconSettings,
	IconAlien,
	IconFileInvoice,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { NavItem } from "./NavItem";
import { NavItemGroup } from "./NavItemGroup";
import { NavSubItem } from "./NavSubItem";
import PlanningIcon from "../../assets/images/icons/planning.svg?react";
import CalculatorIcon from "../../assets/images/icons/calculator.svg?react";
import ConsolidationIcon from "../../assets/images/icons/consolidation.svg?react";
import DataLayerIcon from "../../assets/images/icons/datalayer.svg?react";
import {
	useGetCompanyQuery,
	useGetDataLayerStatusQuery,
} from "../../common/redux/api/exopenApi";
import { useCurrentCompanyDomain } from "../../common/company-domain/useCurrentCompanyDomain.js";
import { useUser } from "../../common/context/userContextUtils";
import { LoadingState } from "../../common/components/atoms/loadingState/LoadingState";
import { useCurrentLegalEntity } from "../../common/legal-entities/useCurrentLegalEntity";
import { CompanySelect } from "./CompanySelect.js";
import { useLegalEntities } from "../../common/legal-entities/useLegalEntities.js";
import { LegalEntitySelect } from "./LegalEntitySelect.js";
import { useLegalEntityHierarchy } from "../../common/legal-entities/useLegalEntityHierarchy.js";
import { Divider } from "../../common/components/atoms/divider/Divider.js";

interface CompanySidebarPartProps {
	sidebarOpen: boolean;
}

export const CompanySidebarPart = ({
	sidebarOpen,
}: CompanySidebarPartProps) => {
	const { companyDomain } = useCurrentCompanyDomain();
	const { legalEntity } = useCurrentLegalEntity();
	const { legalEntities } = useLegalEntities();

	const companyId = companyDomain.id;

	const { data: company, isLoading: isLoadingCompany } = useGetCompanyQuery({
		companyId,
	});
	const { data: hasDataLayer, isLoading: isLoadingDataLayerStatus } =
		useGetDataLayerStatusQuery(companyDomain.id);

	const user = useUser();

	const { t } = useTranslation();
	const { hasAnyCorporateGroup } = useLegalEntityHierarchy();
	const hasCompanyAdmin =
		user.userIsSuperAdmin() || user.userIsCompanyAdmin(companyId);

	const accessToSieUpload =
		hasCompanyAdmin || user.userHasRequiredPermissions(["DataSieUpload"]);

	const accessToDataLayer =
		hasDataLayer &&
		(hasCompanyAdmin || user.userHasRequiredPermissions(["DataLayer"]));

	if (isLoadingCompany || isLoadingDataLayerStatus) {
		return <LoadingState />;
	}

	if (!company || hasDataLayer === undefined) {
		return null;
	}

	const productLinks: JSX.Element[] = [];
	if (accessToDataLayer)
		productLinks.push(
			<NavItem
				key="data layer"
				to={`/${companyId}/datalayer`}
				label="Data Layer"
				icon={<DataLayerIcon />}
			/>,
		);

	if (company.ewr?.url)
		productLinks.push(
			<NavItem
				key="report"
				to={company.ewr.url}
				label="Report"
				icon={<CalculatorIcon />}
				reloadDocument
			/>,
			<NavItem
				key="consolidation"
				to={company.ewr.url}
				label="Consolidation"
				icon={<ConsolidationIcon />}
				reloadDocument
			/>,
		);

	return (
		<div className="grow overflow-y-auto">
			{sidebarOpen && (
				<div className="mx-4 mb-1">
					<Divider label={t("Company domain")} className="my-1" />
					<CompanySelect />
				</div>
			)}
			<nav>
				<div className="mx-4 mb-4 space-y-1">
					<NavItem to={`/${companyId}`} label="Start" icon={<IconHome />} end />
					{productLinks}
					<NavItem
						to={`/company-domains/${companyId}/planning`}
						label="Planning"
						icon={<PlanningIcon />}
					/>

					{hasCompanyAdmin && hasAnyCorporateGroup() && (
						<NavItem
							label={t("Group structure")}
							to={`/${companyId}/administration/hierarchy/legal-entities`}
							icon={<IconBuildingSkyscraper />}
						/>
					)}
					{(hasCompanyAdmin || accessToSieUpload) && (
						<NavItemGroup label="Data" icon={<IconDatabase />}>
							{hasCompanyAdmin && (
								<NavSubItem
									to={`/${companyId}/administration/connectors`}
									label={t("Data sources")}
								/>
							)}
							{accessToSieUpload && (
								<NavSubItem
									to={`/${companyId}/upload/sie`}
									label={t("Upload SIE-file")}
								/>
							)}
						</NavItemGroup>
					)}
					{hasCompanyAdmin && (
						<NavItem
							to={`/${companyId}/settings`}
							label={t("Settings")}
							icon={<IconSettings />}
						/>
					)}
				</div>

				{legalEntities && legalEntities.length > 0 && (
					<>
						{sidebarOpen && (
							<div className="mx-4 mb-1">
								<Divider label={t("Legal entity")} className="my-1" />
								<LegalEntitySelect />
							</div>
						)}
						<div className="mx-4 space-y-1">
							{hasCompanyAdmin && legalEntity !== null && (
								<NavItemGroup
									label={t("Data perspective")}
									icon={<IconHierarchy3 />}
								>
									<NavSubItem
										label={t("Account")}
										to={`/${companyId}/administration/hierarchy/account/hierarchy`}
									/>
									<NavSubItem
										label={t("Cost center")}
										to={`/${companyId}/administration/hierarchy/costcenter`}
									/>
									<NavSubItem
										label={t("Customer")}
										to={`/${companyId}/administration/hierarchy/customer`}
									/>
									<NavSubItem
										label={t("Supplier")}
										to={`/${companyId}/administration/hierarchy/supplier`}
									/>
									<NavSubItem
										label={t("Project")}
										to={`/${companyId}/administration/hierarchy/project`}
									/>
									<NavSubItem
										label={t("Product")}
										to={`/${companyId}/administration/hierarchy/product`}
									/>
								</NavItemGroup>
							)}
							{legalEntity && (
								<NavItemGroup label={t("Invoices")} icon={<IconFileInvoice />}>
									<NavSubItem
										label={t("Customer Invoices")}
										to={`/company-domains/${companyId}/legal-entities/${legalEntity.id}/customer-invoices`}
									/>
									<NavSubItem
										label={t("Supplier Invoices")}
										to={`/company-domains/${companyId}/legal-entities/${legalEntity.id}/supplier-invoices`}
									/>
								</NavItemGroup>
							)}
						</div>
					</>
				)}
				<div className="mx-4">
					{user.userIsSuperAdmin() && (
						<NavItemGroup label={t("Internal")} icon={<IconAlien />}>
							<NavSubItem
								to={`/${companyId}/administration/dev-tools`}
								label="Utvecklingsverktyg"
							/>
							<NavSubItem
								to={`/${companyId}/administration/purchase-price-allocation`}
								label="Manage purchase price allocations"
							/>
							<NavSubItem
								to={`/${companyId}/administration/hierarchy2`}
								label="Manage company hierarchy (new)"
							/>
							<NavSubItem
								to={`/${companyId}/administration/reports`}
								label={t("EWR reports")}
							/>
							<NavSubItem
								to={`/${companyId}/administration/support/users`}
								label={t("Users")}
							/>
							<NavSubItem
								to={`/${companyId}/admin/planning`}
								label="Planning"
							/>
						</NavItemGroup>
					)}
				</div>
			</nav>
		</div>
	);
};
